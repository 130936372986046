<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/seckillGoods' }">秒杀商品</el-breadcrumb-item>
        <el-breadcrumb-item>{{ pageType === 'add' ? '新增' : pageType === 'edit' ? '编辑' : '详情' }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="shebei" v-if="pageType === 'add'">搜索商品</div>
        <div class="content_li-padding" v-if="pageType === 'add'">
          <el-form :model="search" :rules="searchRules" ref="searchRulesForm" label-width="130px" class="demo-ruleForm">
            <el-form-item label="商品名称：" prop="commodityId">
              <div class="flexRow">
                <el-input v-model="search.commodityName" placeholder="请输入商品名称"></el-input>
                <el-button type="primary" @click="getData" style="margin-left:20px;">搜索</el-button>
              </div>
              <span style="color: red">提示：搜索商品以后，在下方选择商品</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="shebei">基本信息</div>
        <div class="content_li-padding">
          <el-form :model="upData" :rules="rules" ref="rulesForm" label-width="130px" class="demo-ruleForm">
            <el-form-item label="选择商品：" prop="commodityId" v-if="pageType === 'add' && commodityList.length>0">
              <div class="flexRow">
                <el-select v-model="upData.commodityId" filterable placeholder="请选择商品">
                  <el-option
                      @click.native="handleSelectGoods(item)"
                      v-for="item in commodityList"
                      :key="item.id"
                      :label="item.commodityName"
                      :value="item.id">
                  </el-option>
                </el-select>
                <span style="color: red" v-if="false">若无数据，请先搜索商品</span>
              </div>
            </el-form-item>
            <el-form-item label="商品名称：" style="width: 100%">
              <span>{{ upData.commodityName }}</span>
            </el-form-item>
            <el-form-item label="秒杀时间：" prop="skStartTime">
              <span v-if="pageType === 'details'">{{ upData.skStartTime === null ? '-':`${upData.skStartTime}至${upData.skEndTime}`}}</span>
              <el-date-picker
                  v-else
                  v-model="datePicker"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  @change="changePicker"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="参与促销活动：" v-if="false">
              <span v-if="pageType === 'details'">{{ upData.isSales === 1 ? '是':'否'}}</span>
              <el-select v-else v-model="upData.isSales" placeholder="请选择是否参与促销活动">
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="促销活动：" v-if="false" prop="salesPromotionId">
              <span v-if="pageType === 'details'">{{ upData.salesPromotionName}}</span>
              <el-select v-else v-model="upData.salesPromotionId" placeholder="请选择促销活动">
                <el-option :label="item.promotionName" :value="item.id" v-for="(item, index) in salesPromotionList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label="秒杀总数：" prop="seckillCount">-->
<!--              <span v-if="pageType === 'details'">{{ upData.seckillCount }}</span>-->
<!--              <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1" :min="0"-->
<!--                               v-model="upData.seckillCount" placeholder="请输入秒杀总数"></el-input-number>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="限购数量：" prop="seckillLimit">-->
<!--              <span v-if="pageType === 'details'">{{ upData.seckillLimit }}</span>-->
<!--              <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1" :min="1"-->
<!--                               v-model="upData.seckillLimit" placeholder="请输入每人限购数量"></el-input-number>-->
<!--            </el-form-item>-->
          </el-form>
        </div>
        <div class="shebei">商品Sku表</div>
        <div class="content_li-padding">
          <el-form ref="tableRuleForm" :model="form" :rules="tableRules">
            <el-table
                border
                :data="form.attrsAssociateInfos"
                v-loading="tableLoading"
                style="width: 100%"
                :header-cell-style="this.utils.getRowClass">
              <el-table-column align="center" label="名称" prop="combinationName"></el-table-column>
              <el-table-column align="center" label="商品规格图">
                <template slot-scope="scope"><img @click="handleSee(scope.row.images)" class="icon-img"
                                                  :src="scope.row.images"/></template>
              </el-table-column>
              <el-table-column align="center" label="售价">
                <template slot-scope="scope">{{ scope.row.sellingPrice }}元</template>
              </el-table-column>
              <el-table-column align="center" label="库存" prop="stock"></el-table-column>
              <el-table-column align="center" label="秒杀总数">
                <template slot-scope="scope">
                  <span v-if="pageType === 'details'">{{ scope.row.seckillCount }}</span>

                  <div class="secKill-price" v-else>
                    <el-form-item style="width: 100%" :prop="'attrsAssociateInfos.'+scope.$index+'.seckillCount'"
                                  :rules="tableRules.seckillCount">
                      <el-input-number type="number" :step-strictly="true"
                                       @blur="handleVerify1(scope.$index)"
                                       :controls="false" :step="1" :min="0" v-model="scope.row.seckillCount"
                                       placeholder="请输入"></el-input-number>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="每人限购数量">
                <template slot-scope="scope">
                  <span v-if="pageType === 'details'">{{ scope.row.seckillLimit }}</span>

                  <div class="secKill-price" v-else>
                    <el-form-item style="width: 100%" :prop="'attrsAssociateInfos.'+scope.$index+'.seckillLimit'"
                                  :rules="tableRules.seckillLimit">
                      <el-input-number type="number" :step-strictly="true"
                                       @blur="handleVerify2(scope.$index)"
                                       :controls="false" :step="1" :min="0" v-model="scope.row.seckillLimit"
                                       placeholder="请输入"></el-input-number>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="秒杀价格">
                <template slot-scope="scope">
                  <span v-if="pageType === 'details'">{{ scope.row.secKillPrice }}元</span>

                  <div class="secKill-price" v-else>
                    <el-form-item style="width: 100%" :prop="'attrsAssociateInfos.'+scope.$index+'.secKillPrice'"
                                  :rules="tableRules.secKillPrice">
                      <el-input-number type="number" :step-strictly="true"
                                       @blur="handleVerify(scope.$index)"
                                       :controls="false" :step="0.01"
                                       :precision="2" :min="0" v-model="scope.row.secKillPrice"
                                       placeholder="请输入"></el-input-number>
                      <span class="unit">元</span>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" v-if="false">
                <template slot-scope="scope">
                  <div class="flex flex_row flex_ju_center">
                    <el-popconfirm
                        @confirm="delRowAddress(scope.row,scope.$index)"
                        title="确定删除该数据吗？">
                      <span class="reference-style" slot="reference">删除</span>
                    </el-popconfirm>
                    <div class="border_center_left" @click="editRowAddress(scope.row,scope.$index)">修改地区</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form>

        </div>
      </div>
    </div>

    <div class="footer-toolbar" v-if="pageType === 'details'">
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="footer-toolbar" v-else>
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button @click="sumbit" type="primary" :loading="btnLoading">确定</el-button>
    </div>
  </div>
</template>

<script>
// let _this;

export default {
  data() {
    const validateSecKillPrice = (rule, value, callback) => {
      // if (value === 0 || value === undefined) {
      //   callback(new Error('请输入秒杀金额'));
      // } else {
      //   callback();
      // }
    };
    const validateSeckillCount = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入秒杀总数'));
      } else {
        callback();
      }
    };
    const validateseckillLimit= (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入每人限购数量'));
      } else {
        callback();
      }
    };
    return {
      search: {
        commodityName: ''
      },
      searchRules: {
        commodityName: [{required: true, message: '请输入商品名称', trigger: 'blur'}]
      },
      rules: {
        // seckillCount: [{required: true, validator: validateSeckillCount, trigger: 'change'}],
        // seckillLimit: [{required: true, validator: validateSeckillLimit, trigger: 'change'}],
        commodityId: [{required: true, message: '请选择商品', trigger: 'change'}],
        skStartTime: [{required: true, message: '请选择秒杀时间', trigger: 'change'}],
        salesPromotionId: [{required: true, message: "请选择促销活动", trigger: "change"}]
      },
      tableRules: {
        // secKillPrice: [{required: true, validator: validateSecKillPrice, trigger: 'change'}],
        // seckillLimit: [{required: true, validator: validateSeckillCount, trigger: 'change'}],
        // seckillCount: [{required: true, validator: validateseckillLimit, trigger: 'change'}],
      },
      salesPromotionList: [],
      total: 0,
      page: 1,
      pageSize: 1000,
      pageType: '',
      tableList: [],
      commodityList: [],
      upData: {
        seckillCount: 0,
        seckillLimit: 1,
        // salesPromotionId: '',
        // isSales: 0,
        skStartTime: '',
        skEndTime: '',
        commodityId: '',
        commodityName: '',
      },
      btnLoading: false,
      tableLoading: false,
      formFlag: false,
      tableFlag: false,
      datePicker: [],
      form: {
        attrsAssociateInfos: []
      },
      selectGoods: {},
      totalStock: 0,
      initialData: {}, // 初始秒杀数据
    };
  },
  filters: {

    // getEvaStatus(value) {
    //   const data = _this.evaStatus.filter(item => item.value == value);
    //   if (data && data.length > 0) {
    //     return data[0].label;
    //   }
    // },
  },
  created() {
    if (this.$route.query.type) {
      this.pageType = this.$route.query.type;
    }
    if (this.$route.query.data) {
      this.upData = JSON.parse(decodeURIComponent(this.$route.query.data));
      this.initialData = JSON.parse(JSON.stringify(this.upData));
      this.datePicker = [this.upData.skStartTime, this.upData.skEndTime];
      this.form.attrsAssociateInfos = JSON.parse(JSON.stringify(this.upData.attrsAssociateInfos));
      this.form.attrsAssociateInfos.forEach(item => {
        item.sellingPrice = (item.sellingPrice / 100).toFixed(2);
        item.secKillPrice = (item.secKillPrice / 100).toFixed(2);
      })
    }
    this.getSalesPromotionData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {
          newUserExclusive: 0
        }
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getCommodityInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.commodityList = res.data.data.records;
              if(_this.commodityList.length===0) {
                _this.$message.info('暂无该商品，请重新搜索');
                _this.search.commodityName = '';
              }
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    changePicker(e) {
      this.upData.skStartTime = e[0];
      this.upData.skEndTime = e[1];
    },
    // 获取促销活动
    getSalesPromotionData() {
      let _this = this;
      _this.$api.getSalesPromotionList().then(res => {
        if (res.data.code == 200) {
          const data = res.data.data;
          _this.salesPromotionList = res.data.data;
          return data;
        }
      });
    },
    // 选择的商品
    handleSelectGoods(val) {
      let _this = this;

      if (val.attrsAssociateInfos && val.attrsAssociateInfos.length === 0) {
        _this.$message.error('该商品暂无规格');
        return
      }
      _this.upData.commodityName = val.commodityName;
      _this.selectGoods = val;
      const list = [];
      val.attrsAssociateInfos.forEach(sku => {
        if (sku.limitedSaleStatus !== 1) {
          sku.sellingPrice = (sku.sellingPrice / 100).toFixed(2);
          list.push(sku);
        }
      })
      val.attrsAssociateInfos = list;
      _this.form = val;
    },
    // 单独校验input

    // 验证秒杀总数
    handleVerify1(i) {
      let _this = this;
      const data = _this.form.attrsAssociateInfos[i];
      // 秒杀总数大于每人限购数量，小于库存
      if(data.seckillCount !== 0 ||data.seckillCount !==null) {
        if(data.stock < data.seckillCount) {
          _this.$message.error('秒杀总数不能大于库存');
          if(_this.pageType === 'add') {
            data.seckillCount = 0;
          }
          if(_this.pageType === 'edit') {
            data.seckillCount = _this.initialData.attrsAssociateInfos[i].seckillCount;
          }
          return;
        }
        if(data.seckillLimit > data.seckillCount) {
          _this.$message.error('秒杀总数不能小于每人限购数量');
          if(_this.pageType === 'add') {
            data.seckillCount = 0;
            // data.seckillLimit = 0;
          }
          if(_this.pageType === 'edit') {
            data.seckillCount = _this.initialData.attrsAssociateInfos[i].seckillCount;
            // data.seckillLimit = _this.initialData.attrsAssociateInfos[i].seckillLimit;
          }
          return;
        }
      }
    },
    // 验证秒杀价格
    handleVerify(i) {
      let _this = this;
      const data = _this.form.attrsAssociateInfos[i];

      // 秒杀价格不能大于商品售价
      if (data.seckillCount !== 0 || data.seckillCount !== null) {
        if(data.sellingPrice < data.secKillPrice) {
          _this.$message.error('秒杀价格不能大于商品售价');
          if(_this.pageType === 'add') {
            data.secKillPrice = 0;
          }
          if(_this.pageType === 'edit') {
            data.secKillPrice = _this.initialData.attrsAssociateInfos[i].secKillPrice;
          }
          return;
        }
      }
    },
    // 验证每人限购数量
    handleVerify2(i) {
      let _this = this;
      const data = _this.form.attrsAssociateInfos[i];

      // 秒杀总数大于每人限购数量
      if(data.seckillLimit !== 0 ||data.seckillLimit !==null) {
        if(data.seckillLimit > data.seckillCount) {
          _this.$message.error('每人限购数量不能大于秒杀总数');
          if(_this.pageType === 'add') {
            data.seckillLimit = 0;
          }
          if(_this.pageType === 'edit') {
            data.seckillLimit = _this.initialData.attrsAssociateInfos[i].seckillLimit;
          }
          return;
        }
      }
    },
    // 修改商品的秒杀价格
    changeGoods(sku) {
      let _this = this;
      const data = JSON.parse(JSON.stringify(_this.selectGoods));
      // newSecKillPrices
      data.attrsAssociateInfos.forEach(item => {
        sku.forEach(v => {
          if (item.id === v.id) {
            item.secKillPrice = v.secKillPrice;
          }
          item.sellingPrice = Number(item.sellingPrice * 100);
        })
      })

      // return
      _this.$api.editCommodityInfo(data).then(res => {
        if (res.data.code == 200) {
          let text = _this.pageType === 'add' ? '添加成功': '修改成功'
          _this.$message.success(text);
          _this.btnLoading = false;
          _this.$router.go(-1);
        }
      });
    },
    // 验证表格
    handleTable() {
      let _this = this;
      this.$refs.tableRuleForm.validate((valid) => {
        if (valid) {
          _this.tableFlag = true;
        } else {
          _this.tableFlag = false;
        }
      })
    },
    // 验证表单
    handleForm() {
      let _this = this;
      _this.formFlag = false;
      this.$refs.rulesForm.validate((valid) => {
        if (valid) {
          // 至少有一个商品规格参加秒杀
          const secKillPrices = _this.form.attrsAssociateInfos.filter(item => item.secKillPrice !== 0 && item.secKillPrice !== undefined);
          if (secKillPrices.length === 0) {
            _this.$message.error('该商品至少有一个规格参加秒杀，请输入秒杀价格');
            return
          }
          _this.formFlag = true;
        } else {
          _this.formFlag = false;
        }
      })
    },
    // 提交
    sumbit() {
      let _this = this;

      _this.btnLoading = true;
      _this.handleTable();
      _this.handleForm();
      if(_this.formFlag && _this.tableFlag) {
        // 至少有一个商品规格参加秒杀
        const secKillPrices = _this.form.attrsAssociateInfos.filter(item => item.secKillPrice !== 0 && item.secKillPrice !== undefined);
        if (secKillPrices.length === 0) {
          _this.$message.error('该商品至少有一个规格参加秒杀，请输入秒杀价格');
          _this.btnLoading = false;
          return
        }

        const newSecKillPrices = JSON.parse(JSON.stringify(secKillPrices));

        newSecKillPrices.forEach(item => {
          item.sellingPrice = Number(item.sellingPrice) * 100;
          item.secKillPrice = Number(item.secKillPrice) * 100;
        })

        if (_this.pageType == 'add') {
          const requestData = {
            attrsAssociateInfos: newSecKillPrices,
            ..._this.upData
          }
          _this.$api.addSeckill(requestData).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("添加成功");
              _this.btnLoading = false;
              _this.$router.go(-1);
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        }
        if (_this.pageType == 'edit') {
          const requestData = JSON.parse(JSON.stringify(_this.upData));
          requestData.attrsAssociateInfos = newSecKillPrices;
          requestData.commodityImgs = requestData.commodityImgs.toString();

          _this.$api.editSeckill(requestData).then(res => {
            if (res.data.code == 200) {
              // 修改商品的规格
              // _this.changeGoods(newSecKillPrices);
              _this.$message.success("添加成功");
              _this.btnLoading = false;
              _this.$router.go(-1);
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        }
      } else {
        _this.btnLoading = false;
      }
      return
      _this.btnLoading = true;
      // 验证秒杀信息
      this.$refs.rulesForm.validate((valid) => {
        if (valid) {
          // if (_this.upData.seckillCount < _this.upData.seckillLimit) {
          //   _this.$message.error('秒杀总数不能少于每人限购数量,请重新输入');
          //   if(_this.pageType === 'add') {
          //     _this.upData.seckillCount = 0;
          //     _this.upData.seckillLimit = 0;
          //     _this.btnLoading = false;
          //   }
          //
          //   if(_this.pageType === 'edit') {
          //     _this.upData.seckillCount = _this.initialData.seckillCount;
          //     _this.upData.seckillLimit = _this.initialData.seckillLimit;
          //     _this.btnLoading = false;
          //   }
          //   return
          // }

          // 至少有一个商品规格参加秒杀
          const secKillPrices = _this.form.attrsAssociateInfos.filter(item => item.secKillPrice !== 0 && item.secKillPrice !== undefined);
          if (secKillPrices.length === 0) {
            _this.$message.error('该商品至少有一个规格参加秒杀，请输入秒杀价格');
            _this.btnLoading = false;
            return
          }

          _this.totalStock = 0;
          const newSecKillPrices = JSON.parse(JSON.stringify(secKillPrices));
          // 计算参加秒杀商品的总库存
          newSecKillPrices.forEach(item => {
            _this.totalStock += item.stock;
            item.sellingPrice = Number(item.sellingPrice) * 100;
            item.secKillPrice = Number(item.secKillPrice) * 100;
          })

          // 秒杀总数不能大于总库存
          if (_this.totalStock < _this.upData.seckillCount) {
            _this.$message.error('秒杀总数不能大于参加秒杀的总库存,请重新输入');
            if(_this.pageType === 'add') {
              _this.upData.seckillCount = 0;
              _this.upData.seckillLimit = 0;
              _this.btnLoading = false;
            }

            if(_this.pageType === 'edit') {
              _this.upData.seckillCount = _this.initialData.seckillCount;
              _this.upData.seckillLimit = _this.initialData.seckillLimit;
              _this.btnLoading = false;
            }
            _this.btnLoading = false;
            return
          }

          if (_this.pageType == 'add') {
            const requestData = {
              attrsAssociateInfos: newSecKillPrices,
              ..._this.upData
            }
            _this.$api.addSeckill(requestData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.$router.go(-1);
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
          if (_this.pageType == 'edit') {
            const requestData = JSON.parse(JSON.stringify(_this.upData));
            requestData.attrsAssociateInfos = newSecKillPrices;
            requestData.commodityImgs = requestData.commodityImgs.toString();

            _this.$api.editSeckill(requestData).then(res => {
              if (res.data.code == 200) {
                // 修改商品的规格
                // _this.changeGoods(newSecKillPrices);
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.$router.go(-1);
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }

          _this.btnLoading = false;
        } else {
          _this.btnLoading = false;
        }
      });
    },
  }
};
</script>
<style scoped lang="less">
.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.content_li {
  padding: 0;
}

.content_li-padding {
  padding: 30px;
  box-sizing: border-box;
}

.content_li-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 2;
}

.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}

/deep/ .el-tag {
  color: #fff !important;
  border: none;
}

.list-input {
  /deep/ .el-input-number {
    width: 100px;
  }
}

.template-title {
  width: 235px;
}

.temp-details {
  display: flex;
  justify-content: flex-start;
}

.temp-details {
  /deep/ .el-form-item {
    //width: 280px;
  }
}

.table-title {
  font-size: 14px;
}

.form-table {
  margin: 0 30px 20px 30px;
}

/deep/ .el-drawer__header {
  font-size: 15px;
}

.drawer-wrap {
  font-size: 15px;
  margin: 0 20px;
  position: relative;
  height: 100%;
}

.drawer-item:nth-of-type(n+2) {
  margin-top: 20px;
}

.drawer-item-header {
  font-weight: 900;
}

.drawer-footer {
  position: absolute;
  bottom: 30px;
  right: 20px;
}

/deep/ .el-form-item {
  width: 30%;
}

.secKill-price {
  /deep/ .el-form-item {
    width: 100%;
    margin-bottom: 0;
  }
}

.unit {
  display: inline-block;
  margin-left: 10px;
}
</style>
